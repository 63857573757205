import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m8410 2205 c0 -3 -89 -133 -197 -288 -109 -155 -209 -299 -223 -320
l-25 -37 -1122 0 -1123 0 0 -227 0 -228 -153 -241 c-83 -132 -161 -253 -173
-269 l-21 -27 -45 68 c-57 87 -134 207 -226 351 l-72 112 0 230 0 231 -1126 2
-1127 3 -99 140 c-232 329 -271 383 -314 443 l-46 62 3046 0 c1675 0 3046 -2
3046 -5z m-3415 -1307 c203 -322 225 -356 287 -453 24 -38 53 -85 63 -102 11
-18 22 -33 26 -33 10 0 206 305 508 786 l54 87 361 -7 c199 -3 594 -6 880 -6
435 0 517 -2 513 -14 -3 -7 -104 -151 -224 -320 l-218 -306 -132 0 -133 0 0
-845 0 -844 -32 -38 c-18 -21 -49 -56 -69 -78 -20 -22 -90 -101 -155 -175 -66
-74 -212 -238 -324 -365 -240 -271 -311 -351 -339 -384 -12 -13 -23 -22 -25
-20 -2 2 -7 216 -11 474 l-7 470 29 30 c15 17 68 77 118 135 49 58 93 107 96
110 9 7 18 1500 9 1500 -3 0 -127 -152 -275 -337 -148 -186 -347 -436 -442
-556 -96 -120 -178 -215 -183 -212 -4 3 -77 92 -160 198 -84 105 -165 208
-180 227 -15 19 -110 139 -211 265 -356 448 -332 420 -339 399 -4 -10 -8 -346
-9 -747 l-2 -728 125 -137 126 -137 0 -473 c0 -348 -3 -471 -11 -466 -6 4 -28
25 -48 47 -20 22 -41 45 -46 51 -6 6 -138 153 -295 326 -156 173 -308 340
-336 370 -28 30 -89 97 -134 148 l-83 93 4 847 4 847 -133 3 -132 3 -68 92
c-67 90 -227 309 -306 417 -22 30 -52 72 -68 92 -19 25 -24 38 -15 41 6 3 406
6 888 6 l877 1 177 -282z m335 -1680 c25 -32 47 -57 50 -57 3 -1 77 89 165
199 l160 201 6 -93 c4 -51 7 -454 8 -895 l1 -803 -97 0 c-54 0 -209 -3 -345
-7 l-248 -6 0 913 0 912 128 -154 c70 -84 147 -179 172 -210z m-852 -1592 c51
-28 62 -62 62 -188 l0 -118 -155 0 -155 0 0 40 0 40 -70 0 -70 0 0 -60 0 -60
223 -2 222 -3 3 -367 c1 -203 -1 -368 -4 -368 -3 0 -84 25 -179 55 -94 30
-182 55 -195 55 -12 0 -100 -25 -195 -55 -94 -30 -175 -55 -178 -55 -4 0 -7
99 -7 220 l0 220 155 0 155 0 0 -50 0 -50 70 0 70 0 0 75 0 75 -225 0 -225 0
0 305 0 305 338 0 c255 -1 343 -4 360 -14z m442 -366 l0 -380 70 0 70 0 0 380
0 380 121 0 121 0 34 -34 34 -34 0 -516 c0 -284 -3 -516 -6 -516 -4 0 -84 25
-179 55 -94 30 -179 55 -189 55 -15 0 -135 -35 -306 -89 -36 -11 -68 -21 -72
-21 -5 0 -8 228 -8 506 0 449 2 509 16 533 30 51 58 60 182 61 l112 0 0 -380z
m1239 354 c41 -34 51 -77 51 -217 l0 -117 -67 -64 -66 -64 66 -64 67 -63 0
-243 c0 -171 -3 -242 -11 -242 -6 0 -91 26 -188 57 -97 31 -183 56 -191 54 -8
-1 -93 -27 -187 -57 -95 -31 -176 -53 -181 -50 -4 2 -7 17 -6 33 1 15 3 261 3
546 l1 517 339 0 339 0 31 -26z m821 11 c50 -26 61 -61 58 -188 l-3 -112 -152
-3 -153 -3 0 41 0 40 -70 0 -70 0 0 -60 0 -60 225 0 225 0 0 -370 c0 -203 -3
-370 -7 -370 -3 0 -84 25 -180 55 -96 30 -184 55 -195 55 -11 0 -98 -25 -193
-55 -94 -30 -175 -55 -178 -55 -4 0 -7 99 -7 220 l0 221 153 -3 152 -3 3 -47
3 -48 70 0 69 0 0 75 0 75 -225 0 -225 0 0 305 0 305 335 0 c281 0 341 -2 365
-15z"
          />
          <path
            d="M5756 2785 c-9 -24 -7 -93 2 -99 4 -2 37 -6 72 -8 l65 -3 0 60 0 60
-66 3 c-54 2 -68 0 -73 -13z"
          />
          <path
            d="M5754 2417 c-3 -8 -4 -41 -2 -73 l3 -59 73 -3 73 -3 -3 73 -3 73 -68
3 c-51 2 -69 -1 -73 -11z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
